import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as InfoIcon } from 'src/assets/svg/close.svg'
import { useApp } from 'src/hooks'
import styled from 'styled-components'

const HomeBanner = () => {
    const dispatch = useDispatch()

    const { app_setShowBanner, showBanner } = useApp()

    const onClickRemove = useCallback(() => {
        dispatch(app_setShowBanner(false))
    }, [dispatch, app_setShowBanner])

    if (showBanner) {
        return (
            <Wrapper>
                <h4>
                    Try our mobile app to get access to more features.
                </h4>
                <button onClick={onClickRemove}>
                    <InfoIcon />
                </button>
            </Wrapper>
        )
    }

    return null
}

const Wrapper = styled.div`
    padding: 0.3rem 2rem 0.3rem 0.3rem;
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffce8e;
    border-radius: 0;
    z-index: 11;
    text-align: center;
    h4 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
    }

    button {
        height: 24px;
        width: 24px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: transparent;
        border: transparent;
        position: absolute;
        top: 2px;
        right: 4px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    @media screen and (min-width: 600px) {
        h4 {
            font-size: 16px;
        }
    }
`

export default HomeBanner
