import React from 'react'
import { Helmet } from 'react-helmet'
import img from 'src/assets/images/maintenance.webp'
import fingoLogo from 'src/images/fingo-logo.png'
import styled from 'styled-components'
import GooglePlay from 'src/assets/images/googleplay.png'
import AppStore from 'src/assets/images/appstore.png'

const MaintenancePage = () => {
    return (
        <>
            <Helmet>
                <title>🚧 Maintenance</title>
            </Helmet>
            <Wrapper>
                <img className='maintenance' src={img} alt='Maintenance' />
                <h1>🚧 Men at work</h1>
                <p>
                    We are fixing some issues, please use our mobile app or come
                    back after some time.{' '}
                </p>
                <div>
                    <AppLinkWrapper>
                        <a
                            href='https://play.google.com/store/apps/details?id=com.tryfingo.mobile'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img src={GooglePlay} alt='google play' />
                        </a>
                        <a
                            href='https://apps.apple.com/app/fingo-learn-finance/id6479199378'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img src={AppStore} alt='appstore' />
                        </a>
                    </AppLinkWrapper>
                </div>

                <img className='logo' src={fingoLogo} alt='Maintenance' />
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 1rem;
    .maintenance {
        width: 200px;
        height: auto;
        margin-bottom: 1rem;
    }
    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    .logo {
        height: 40px;
        width: auto;
    }
    @media screen and (min-width: 600px) {
        .maintenance {
            width: 320px;
            height: auto;
        }
        h1 {
            font-size: 2rem;
            font-weight: bold;
        }
    }
`

const AppLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    margin-bottom: 3rem;
    img {
        height: 40px;
        width: auto;
        margin: 0 0.5rem;
    }

    @media screen and (min-width: 600px) {
        img {
            height: 45px;
        }
    }
`

export default MaintenancePage
